const colors = {
  first: "#ffffff",
  second: "#e5e5e5",
  third: "#fca311",
  fourth: "#14213d",
  fifth: "#000000",
  six: "rgb(236, 233, 233)"
};

export default colors;
